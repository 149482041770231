.articles-page {
    display: grid;
    grid-template-columns: 85% 15%;
    grid-template-rows: auto;
    grid-template-areas: 
      "articlesMain topics";
    padding: 5rem;
    background-color: white;
    min-height: 100vh;
  }
  
.articles-main {
    grid-area: articlesMain;
    padding: 3rem;
    padding-right: 10rem;
}

.article {
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
    padding-bottom: 5rem;
    position: relative;
}

.article-image-container {
    width: 70%;
    height: 13rem;
    margin-top: -0.25rem;
}

.article-image {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
}

.article-title {
    margin-left: 8%;
    width: 100%;
}

.article-title span {
    background-color: #7d22ea;
    color: white;
    padding: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.35rem;
    font-size: 35px;
    font-weight: bolder;
    margin-top: 0;
    cursor: pointer;
    -webkit-box-decoration-break: clone;
}


/* .article-title p::before{
    content: attr(data-content);
    background-color: #7d22ea;
    padding: 1rem;
} */

.article-author {
    position: absolute;
    right: 0;
    bottom: 5rem;
    font-size: 20px;
}

.article-date {
    position: absolute;
    right: 0;
    bottom: 4rem;
    font-size: 18px;
    color: #7c7373;
}

.topics {
    font-size: 23px;
    padding: 2rem;
}

.topics-list p {
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
}

.topics p.selected {
    background-color: #7d22ea;
    padding: 0.5rem;
    color: white;
}

@media only screen and (max-width: 1400px) {
    .articles-page {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        padding: 2rem;
    }

    .articles-page .tag {
        font-size: 27px;
    }

    .articles-main {
        padding: 0;
    }

    .article {
        flex-direction: column;
    }

    .article-image-container {
        width: 100%;
    }

    .article-title {
        margin: 0;
    }

    .article-author {
        bottom: 2rem;
    }

    .article-date {
        bottom: 1rem;
    }

    .topics {
        padding: 0;
    }

    .topics-list p {
        display: inline-block;
        line-height: 0.1rem;
        margin-right: 2rem;
    }

}

@media only screen and (max-width: 680px) {
    .article-title span {
        font-size: 28px;
    }
}