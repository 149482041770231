@font-face {
  font-family: "Space Grotesk";   /*Can be any text*/
  src: local("VariableFont_wght"),
    url("fonts/SpaceGrotesk-VariableFont_wght.ttf") format("truetype");
}

body {
  /* background-color: #101c1c; */
  /* background-color: white; */
  background-color: black;
  /* color: white; */

  /* background-color: #ffd536;; */
  /* background-color: #7d22ea; */
  font-size: 29px;
  font-family: "Space Grotesk", sans-serif;
}

.ant-collapse {
  font-family: "Space Grotesk", sans-serif;
}

.ant-collapse>.ant-collapse-item >.ant-collapse-header, .ant-collapse .ant-collapse-content {
  /* color: #ffd536;
  background-color: #7d22ea; */
  background-color: #101c1c;
  color: white;
}

.ant-collapse>.ant-collapse-item >.ant-collapse-header .ant-collapse-arrow {
  font-size: 1.5rem;
}

.ant-collapse>.ant-collapse-item >.ant-collapse-header {
  display: flex;
  align-items: center;
}

.ant-collapse {
  border-left: 0;
  border-right: 0;
}

.ant-collapse>.ant-collapse-item >.ant-collapse-header, .ant-collapse .ant-collapse-content {
  border-top: 0;
}

.App {
  text-align: center;
  min-height: 100vh;
  color: #101c1c; 
}


.square-container {
  width: 2.7vw;
  position: absolute;
  right: 3.8rem;
  margin-top: -10rem;
}

.square {
  aspect-ratio: 1 / 1;
  width: 100%;
  max-height: 100%;
  background-color: #7d22ea;
}

.info-area {
  /* background-color: white;
  color: black; */
  background-color: black;
  color: white;
  padding-top: 9rem;
  padding-left: 147px;
  padding-right: 147px;
  padding-bottom: 11rem;
  text-align: left;
}

.info-area-header {
  text-align: left;
}

.info-box {
  display: flex;
}

.info-list {
  display: flex;
  justify-content: space-between;
  padding-top: 6rem;
  padding-left: 30px;
}

.info-container {
  display: flex;
  flex-direction: column;
  width: 29%;
}

.info-title {
  display: flex;
  font-weight: 700;
  flex-direction: column;
}

.info-content {
  display: flex;
  padding-top: 3rem;
  font-weight: 400;
  font-size: 24px;
}



/* .tables {
  background-color: white;
  color: black;
  padding-top: 8rem;
  padding-bottom: 1rem;
}

.table {
  display: grid;
  grid-template-columns: 22% 69%;
  grid-template-rows: auto;
  grid-template-areas: 
    "tableLeft tableRight";
  margin-left: 142px;
  margin-bottom: 3.8rem;
}

.table>div {
  height: 100px;
  position: relative;
  border-top: 2px solid #7d22ea;
  text-align: left;
}

.table-Q.tag {
  text-align: left;
  margin-bottom: 3rem;
  font-weight: bold;
  font-size: 38px;
}


.table-left {
  grid-area: tableLeft;
  padding-left: 2rem;
}

.table-left:after {
  content: '';
  width: 2px;
  height: 10rem;
  background: #7d22ea;
  position: absolute;
  right: -6px;
  top: 0px;
}

.table-left.table-left-long:after {
  content: '';
  width: 2px;
  height: 12rem;
  background: #7d22ea;
  position: absolute;
  right: -6px;
  top: 0px;
}

.table-left>p {
  background-color: #7d22ea;
  color: #ffd536;
  display: inline-block;
  padding: 1rem;
  font-size: 38px;
  font-weight: bolder;
  line-height: 1.5rem;
}

.table-left :nth-child(1) {
  margin-bottom: 0;
  padding-top: 1rem;
  padding-bottom: 0.6rem;
}

.table-left :nth-child(3) {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 1rem;
  padding-top: 0.4rem;
}

.table-left :nth-child(5) {
  margin-top: -1rem;
  padding-bottom: 1rem;
}

.table-right {
  grid-area: tableRight;
  padding-top: 2rem;
  padding-left: 7rem;
  padding-right: 13rem;
  line-height: 2rem;
}

.hr-container {
  margin-left: 142px;
  margin-bottom: 6rem;
  margin-top: 6rem;
  width: 83.5%;
}

.hr {
  border-color: #7d22ea;
} */

.compare-area {
  display: flex;
  flex-direction: column;
  padding-left: 140px;
  padding-top: 9rem;
  padding-bottom: 4rem;
  background-color: #101c1c;
}

/* .compare-area {
  display: grid;
  grid-template-columns: 44% 50%;
  grid-template-rows: auto;
  grid-template-areas: 
    "compareLeft compareRight";
  padding: 4.5rem 0;
  padding-bottom: 7rem;
  background-color: white;
} */

/* .compare-area .tag {
  color: #7d22ea;
} */

/* .compare-left {
  grid-area: compareLeft;
  position: relative;
  padding-left: 11rem;
  padding-right: 5rem;
}

.compare-right {
  grid-area: compareRight;
  padding-left: 9rem;
  padding-right: 10rem;
}

.compare-left:after {
  content: '';
  width: 2px;
  height: 23rem;
  background: #ffd536;
  position: absolute;
  right: -6px;
  opacity: 0.5;
} */

.compare-left, .compare-right {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 90%;
}

.tag {
  margin-bottom: 0;
  border-left: solid 0.5rem #7d22ea;
  padding: 0.3rem;
  padding-left: 1.5rem;
  font-weight: bold;
  font-size: 32px;
  /* line-height: 1.2rem; */
  line-height: 1;
  text-align: left;
}

.compare-area .tag {
  color: white
}

.center-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 4rem
}

.center-container > p {
  text-align: left;
  /* background-color: #7d22ea; */
  color: white;
  padding: 1rem;
}
/* 
.compare-left .center-container {
  padding-left: 15rem;
  padding-right: 9rem;
}

.compare-right .center-container {
  padding-left: 13rem;
} */

.articles-area {
  padding: 9rem;
  /* background-color: white;
  color: black; */
  background-color: black;
  color: white;
  padding-bottom: 10rem;
}

.articles-area .article-title span {
  font-size: 30px;
}

.articles-area-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.view-all {
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

.article-box-list {
  display: flex;
  /* width: 100vw; */
}

.article-box {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.article-box .article-title, .article-box .article-image-container {
  width: 80%;
  margin: 0;
  margin-bottom: 3rem;
}


.QA {
  padding: 7rem;
  padding-left: 11rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  background-color: #101c1c;
  color: white;
}

/* .QA p {
  color: black;
} */

/* .QA .tag {
  color: black;
  background-color: white;
} */

.Q {
  font-weight: bold;
  font-size: 35px;
  line-height: 2rem;
}

.A {
  font-size: 29px;
  margin-top: 0;
  margin-bottom: 0.8rem;
  line-height: 2rem;
}


@media only screen and (max-width: 1400px) {
  body {
    font-size: 27px;
  }  

  .square-container {
    width: 50px;
  }

  /* .tables {
    margin-top: 11rem;
  }
  
  .table {
    grid-template-columns: 35% 60%;
    margin-left: 42px;
  }

  .table-Q.tag {
    font-size: 35px;
  }

  .table-left {
    padding-left: 0;
  }

  .table-left>p, .tag {
    padding: 1rem;
    font-size: 35px;
    line-height: 1rem;
  }

  .table-right {
    padding: 1rem;
    padding-left: 2rem;
    line-height: 2rem;
  }
  
  .table-left :nth-child(3) {
    margin-top: -0.2rem;
    padding-top: 0.7rem;
  }

  .table-left.table-left-long:after {
    height: 10rem;
  }

  .hr-container {
    margin-left: 42px;
    margin-top: 0;
    width: 90%;
  } */

  .info-area {
    padding-left: 25px;
    padding-right: 45px;
    padding-top: 4rem;
    padding-bottom: 2rem;
  }


  .info-list { 
    flex-direction: column;
    padding-top: 3rem;
  }

  .info-container {
    width: 100%;
  }

  .info-title {
    display: inline-block;
  }

  .info-title span {
    padding-right: 10px;
  }

  .info-content {
    font-weight: 300;
    padding-top: 0;
    padding-bottom: 2rem;
  }

  .compare-area {
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
    padding-left: 0;
  }

  .compare-left, .compare-right {
    padding-left: 3rem;
    padding-right: 4rem;
  }

  .center-container {
    padding-left: 1rem;
    margin-bottom: 2rem;
    margin-top: 0;
  }

  .articles-area {
    padding-left: 50px;
  }

  .QA {
    padding-left: 2rem;
    padding-right: 2.3rem;
  }

  .Q {
    font-size: 29px;
    /* margin-top: 1.5rem; */
  }

  /* .QA :nth-child(2){
    margin-top: 4.5rem;
  } */

  .Q, .A {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 750px) {
  .table>div {
    height: auto;
  }
}

@media only screen and (max-width: 680px) {
  body {
    font-size: 22px;
  }

  .tag {
    font-size: 27px;
  }

  .square-container {
    width: 30px;
    right: 30px;
  }

  /* .tables {
    padding: 1rem;
    margin-top: -4rem;
    padding-top: 5rem;
  }
  .table-left {
    padding-top: 0.3rem;
  }

  .table-left>p, .tag {
    font-size: 18px;
  }  

  .table-left>p {
    padding: 0.4rem;
  }  

  .tag {
    padding: 0.5rem;
  }

  .table-left :nth-child(1) {
    padding-top: 0.5rem;
    padding-bottom: 0.3rem;
  }
  .table-left :nth-child(3) {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .table-left :nth-child(5) {
    padding-bottom: 0.5rem;
  }

  .table {
    grid-template-columns: 39% 61%;
    margin-left: 15px; 
    margin-bottom: 0;
  }

  .table-Q.tag {
    font-size: 22px;
  }

  .table-right {
    border-left: 2px solid #7d22ea;
    line-height: 1.2rem;
    padding: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0;
  }

  .table-left:after, .table-left.table-left-long:after {
    height: 0;
  }
  
  .hr-container {
    margin-left: 15px;
    width: 97%;
    margin-bottom: 3rem;
    margin-top: 0;
  } */

  .info-content {
    font-size: 22px;
  }

  .compare-area {
    padding: 2rem;
    padding-left: 1.5rem;
  }
  .compare-left:after {
    width: 0;
  }
  .compare-left, .compare-right {
    padding-left: 0;
  }
  .center-container {
    padding-left: 0.5rem;
  }

  .articles-area {
    padding-left: 35px;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .articles-area-header {
    width: 83vw;
  }

  .article-box-list {
    flex-direction: column;
    width: 93vw;
  }

  .article-box {
    width: 100%;
  }

  .article-title p {
    font-size: 23px !important;
  }

  .article-box .article-title, .article-box .article-image-container {
    width: 90%;
  }

  .article-image-container {
    margin-bottom: 1rem !important;
  }

  .view-all {
    font-size: 15px;
  }

  .QA {
    padding-top: 4rem;
  }
  .Q, .A {
    font-size: 22px;
    /* line-height: 1.5rem; */
  }
}