

.editor-page {
    padding: 15rem;
    padding-top: 5rem;
    background-color: white;
    font-size: 23px;
}

.title-input {
    width: 100%;
    min-height: 3rem;
    margin-bottom: 3rem;
    padding: 1rem;
}

.editable {
    border: solid;
    padding: 2rem;
    min-height: 30rem;
}

.submit-button {
    width: 20%;
    height: 3rem;
    margin-top: 3rem;
    margin-left: 35%;
    cursor: pointer;
}

.author-input {
    width: 100%;
    min-height: 2rem;
    margin-bottom: 3rem;
    padding: 1rem;
}

.create-article-container {
    display: flex;
    justify-content: center;
    height: 50vh;
    align-items: center;
}

.create-article-container button {
    cursor: pointer;
}

.medium-editor-element img {
    max-width: 100%;
}