
.top-nav {
    height: 180px;
    /* background-image: url('images/topnav-bg.jpg');
    background-size: contain; */
    
    color: white;
}

.logo {
    width: 7rem;
    position: absolute;
    top: 15px;
    left: 130px;
    cursor: pointer;
}
  
  
.menu, .twitter, .insights {
    width: 100px;
    position: absolute;
    top: 55px;
    font-size: 22px;
    cursor: pointer;
    font-weight: medium;
    /* color: white; */
}
  
.twitter {
    right: 70px;
}

.insights {
    /* right: 290px; */
    right: 200px;
}

.menu {
    right: 160px;
}



@media only screen and (max-width: 1400px) {
    .logo {
      width: 5rem;
      /* top: 5rem; */
      left: 50px;
    }
  
    /* .menu, .twitter {
      right: 50px;
    } */

}


@media only screen and (max-width: 680px) {
  .top-nav {
    height: 140px;
  }

    .logo {
      width: 3rem;
      top: 2rem;
      left: 2rem;
    }
  
    .menu, .twitter, .insights {
      width: 30px;
      font-size: 15px;
      top: 3rem;
      /* right: 42px; */
    }

    .twitter {
      right: 10px;
    }
  
    .insights {
      /* right: 130px; */
      right: 80px;
    }

    .menu {
        right: 60px;
    }
}