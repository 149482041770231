.Top-description, .Top-description-mobile {
    text-align: left;
    /* font-size: 79px; */
    font-weight: 500;
    /* padding-left: 140px; */
    padding-top: 14.5rem;
    padding-bottom: 12rem;
    line-height: 5.5rem;
    background-size: cover;
    padding-left: 16%;
    padding-right: 16%;
    background-image: url("images/top-bg-7.jpg");
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }

  .Top-description-mobile {
    padding-left: 1.5rem;
  }

  
  .Top-description span, .Top-description-mobile span{
    /* background-color: #7d22ea; */
    display: inline-block;
    padding: 0.6rem 1.8rem;
  
    /* background-color: #101c1c; */
    /* color: #7d22ea; */
    /* color: #ffd536; */
    /* color: white; */
    /* text-align: center; */
    line-height: 6.5rem;
  }
  
  /* .Top-description p > span {
    font-weight: 500;
    padding-left: 0.1rem;
  } */
  
  /* .Top-description :nth-child(1){
    margin-bottom: 0;
  } */
  
  .Top-description :nth-child(1){
    /* margin-top: 2.5rem; */
    margin-top: 0.5rem;
    margin-bottom: 0;
    /* margin-left: -2rem; */
    width: 41vw;
  }

  .Top-description :nth-child(2){
    padding-top: 0;
  }

  .description-container {
    color: white;
  }

  /* .description-container {
    display: flex;
    justify-content: center;
  } */
  
  /* .Top-description :nth-child(2){
    margin-top: 0;
    padding-bottom: 2rem;
    padding-top: 0;
    margin-left: 30%;
  } */
  
  /* .description-text-container {
    display: flex;
    justify-content: center;
  } */
  

  @media only screen and (max-width: 1400px) {
    /* .Top-description {
      margin-left: 50px;
    } */
  
  /* 
    .Top-description {
      font-size: 80px;
      margin-left: 2rem;
      margin-top: 12.7rem;
      margin-bottom: 8rem;
      line-height: 3.5rem;
    } */
  
    .Top-description {
      background-size: contain;
    }
  
}


@media only screen and (max-width: 680px) {
    .Top-description-mobile {
      font-size: 35px;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      padding-top: 10rem;
      line-height: 3rem;
      /* background-image: url("images/top-bg-mobile.jpg"); */
      /* height: 220px; */
      background-size: contain;
    }
    .Top-description-mobile span {
      padding: 0.1rem 1.8rem;
      line-height: 4.5rem;
      /* padding: 0.5rem; */
      /* display: none; */
    }
    /* .Top-description :nth-child(1) {
      width: unset;
      margin: 0;
    }
    .Top-description :nth-child(2) {
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 0.5rem;
      margin-left: 0;
    } */

}