
.article-page {
    display: grid;
    grid-template-columns: 29% 71%;
    grid-template-rows: auto;
    grid-template-areas: 
      "authorSection articleMain";
    padding: 9rem;
    padding-top: 13rem;
    background-color: white;
    min-height: 100vh;
}

.article-page .twitter {
    position: initial;
    color: black;
    padding-top: 1rem;
    margin-bottom: 4rem;
}

.article-page-author {
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: flex;
}

.written-by {
    color: rgb(115, 113, 113);
    font-size: 18px;
}

.article-tags-container {
    padding-right: 2rem;
    margin-bottom: 3rem;
}

.article-tag {
    font-size: 18px;
    padding: 0.6rem;
    color: black !important;
    margin-top: 1rem;
}

.article-main {
    grid-area: articleMain;
    position: relative;
}

.author-section {
    grid-area: authorSection;
    font-size: 20px;
    line-height: 0.1rem;
}

.author-name {
    font-weight: bold;
}

.author-title {
    color: rgb(115, 113, 113);
}

.article-content {
    font-size: 23px;
    line-height: 2rem;
    padding: 1rem;
    width: 85%;
}

.article-page .article-title {
    margin: 0;
    width: 50%;
}

.article-page .article-date {
    position: initial;
    text-align: right;
    padding-right: 15%;
    margin-bottom: 3rem;
}

.author-image-container {
    width: 5rem;
    margin-right: 3rem;
}

.author-image {
    width: 100%;
}

.nav {
    color: #7d22ea;
    position: absolute;
    top: 14rem;
    cursor: pointer;
}

/* .article-content p:not(:has(br)) {
    display: contents;
} */

.article-content p {
    margin: 0;
}

.article-content h1 {
    line-height: 1;
}

.article-content img {
    max-width: 100%;
}


/* @media only screen and (max-width: 1140px) { */
@media only screen and (max-width: 1500px) {
        .article-page {
        display: flex;
 	    flex-direction: column;
 	    padding: 1rem;
    }

    .article-page-author {
        margin-top: 8rem;
        margin-bottom: 3rem;
    }

    .article-page .article-title {
        width: 100%;
    }

    .article-content {
        width: 95%;
    }

}